import React, { useMemo, PropsWithChildren } from 'react'

type NestedComposerProps = PropsWithChildren<{
  components: React.JSXElementConstructor<any>[]
}>

// based on https://stackoverflow.com/questions/51504506/too-many-react-context-providers
export const NestedComposer = ({ components, children }: NestedComposerProps) => {
  const composed = useMemo(
    () =>
      components.reduceRight((acc, CurrentComponent) => <CurrentComponent>{acc}</CurrentComponent>, children),
    [children, components]
  )
  return <>{composed}</>
}
