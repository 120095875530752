import { CurrencyIsoCode } from 'types/graphql-global-types'

export const localeDomains = {
  en: 'https://app.linus-finance.com',
  de: 'https://app.linus-finance.com',
  uk: 'https://app.linus-finance.co.uk',
}

export enum Platform {
  UK = 'uk',
  EU = 'eu',
}

export const platformMap = {
  'app.linus-finance.com': Platform.EU,
  'app.linus-finance.co.uk': Platform.UK,
}

export const getPlatform = () =>
  window.location.host === localeDomains.uk ||
  process.env.REACT_APP_PLATFORM === platformMap['app.linus-finance.co.uk']
    ? platformMap['app.linus-finance.co.uk']
    : platformMap['app.linus-finance.com']

export const grid: Record<number, number[]> = {
  0: [0, 0, 0, 0],
  1: [20, 65, 65, 73],
  2: [40, 130, 130, 146],
  3: [60, 195, 195, 219],
  4: [80, 260, 260, 292],
  5: [100, 325, 325, 365],
  6: [120, 390, 390, 438],
  7: [140, 455, 455, 511],
}

export const gridMix = (sm: number, md: number, lg: number, xl: number) => [
  grid[sm][0],
  grid[md][1],
  grid[lg][2],
  grid[xl][3],
]

export const generateUniqueId = (): string => Math.random().toString(36).substring(2, 9)

export const environment = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
  TEST: 'test',
}

// TODO: move to env variables - don't expose our infrastructure in code
export const domains = {
  [environment.PRODUCTION]: {
    [Platform.EU]: 'https://app.linus-finance.com',
    [Platform.UK]: 'https://app.linus-finance.co.uk',
  },
  [environment.STAGING]: {
    [Platform.EU]: 'https://app-staging.linus-finance.com',
    [Platform.UK]: 'https://app-staging.linus-finance.co.uk',
  },
  [environment.DEVELOPMENT]: {
    [Platform.EU]: 'http://auth.localhost:3000',
    [Platform.UK]: 'http://auth.localhost:3000',
  },
}

export const webAppLocales = {
  'de-DE': 'de',
  'en-GB': 'en',
  en: 'en',
}

export const salesforceLanguage = {
  'de-DE': 'DE',
  'en-GB': 'EN',
  en: 'EN',
}

export const nonDisclosureFiles = {
  'de-DE':
    'https://static-authetification-files.s3.eu-central-1.amazonaws.com/LINUS-Vertraulichkeitsvereinbarung.pdf',
  en: 'https://static-authetification-files.s3.eu-central-1.amazonaws.com/LINUS-non-disclosure-agreement.pdf',
  'en-GB':
    'https://static-authetification-files.s3.eu-central-1.amazonaws.com/LINUS-non-disclosure-agreement.pdf',
}

export const growthCardImage = 'https://manualupload.s3.eu-central-1.amazonaws.com/GrowthOverview.png'

export const nestedRoutes = {
  VERIFICATION: 'verification',
  CHECKOUT_INVESTMENT_ACCOUNT: 'investment-account',
  CHECKOUT_INVESTMENT_DETAILS: 'investment-details',
  CHECKOUT_SIGN_CONTRACT: 'sign-contract',
  CHECKOUT_COMPLIANCE: 'compliance',
  CHECKOUT_CLASSIFICATION: 'classification',
  CHECKOUT_IDENTIFICATION: 'identification',
}

export const routes = {
  AUTH_CALLBACK: '/auth-callback',
  REGISTRATION: '/registration',
  ACCOUNT: '/account',
  CONTACT: '/contact',
  IMPRINT: '/imprint',
  ID_VERIFICATION: '/id-verification',
  PORTFOLIO: '/portfolio',
  CONTACT_DETAILS: '/contact-details',
  CHECKOUT: '/checkout',
  OVERVIEW: '/overview',
  INVESTOR_IDENTIFICATION: '/investor-identification',
  LOGIN: '/login',
  LOGOUT: '/logout',
  PRODUCT_DETAIL: '/product',
  PRODUCT_DETAIL_DEV: '/dev/*',
  AUTH_ERROR: '/error',
  REGISTER: '/register',
  REGISTER_VERIFICATION: `/register/${nestedRoutes.VERIFICATION}`,
  CLASSIFICATION: '/classification',
  SECRET_LINK: '/secret-link',
}

export const isPublicPath = (pathname: string) => {
  const publicRoutes = [
    routes.CONTACT_DETAILS,
    routes.AUTH_ERROR,
    routes.REGISTER,
    routes.REGISTER_VERIFICATION,
    routes.LOGOUT,
    routes.LOGIN,
  ]
  return publicRoutes.includes(pathname)
}

export const i18nNumberFormats = {
  'de-DE': {
    currency: 'EUR',
    symbol: '€',
  },
  en: {
    currency: 'EUR',
    symbol: '€',
  },
  'en-GB': {
    currency: 'GBP',
    symbol: '£',
  },
  'en-US': {
    currency: 'USD',
    symbol: '€',
  },
}

export const currencyLocaleMap = {
  [CurrencyIsoCode.EUR]: i18nNumberFormats.en,
  [CurrencyIsoCode.GBP]: i18nNumberFormats['en-GB'],
  [CurrencyIsoCode.USD]: i18nNumberFormats['en-US'],
}

export const CALENDLY_ONBOARDING_LINK = 'https://calendly.com/linus-finance/onboarding-call-{locale}'

export const isDevelop = process.env.NODE_ENV === environment.DEVELOPMENT
export const isProduction = process.env.NODE_ENV === environment.PRODUCTION
export const isTest = process.env.NODE_ENV === environment.TEST
