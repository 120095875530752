import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useRef,
  useState,
  PropsWithChildren,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'

import { gql, useLazyQuery } from '@apollo/client'
import { Loading } from 'components/Loading'
import { useTranslate } from 'hooks/useTranslate'
import { InvestmentRequest_investmentRequest_deal } from 'pages/Checkout/CheckoutWizard/gql-query-types/InvestmentRequest'
import { useLocation, useParams } from 'react-router-dom'
import { FormData } from 'types/CheckoutFormData'
import { InvestmentRequestStatus, StageName } from 'types/graphql-global-types'
import { AvailableAuthorizedSignatory, Stage } from 'types/Stages'
import { routes } from 'utils/constants'

import { Product, Product_product } from './gql-query-types/Product'
import { normalizeInvestmentRequest } from './utils'

export type Config = {
  stageTitles: string[]
  stages: Stage[]
  offset: number
  hasNext: boolean
  totalStages: number
}

export type ConfigInput = {
  stageTitles: string[] | null
  stages: string | null
  offset: number | null
  hasNext: boolean | null
  totalStages: number | null
} | null

export type InvestmentRequest = {
  id?: string
  formData: FormData
  interestRate: number | null
  status: InvestmentRequestStatus
  coInvestmentStageName: StageName | null
  checkoutFormConfig: {
    main: Config
    authorizedSignatory: Config
  }
  authorizedSignatories?: AvailableAuthorizedSignatory[]
  fundName: string | null
}

export type UpdateInvestmentRequestType = {
  id?: string | null
  formData?: string | null
  interestRate?: number | null
  status?: InvestmentRequestStatus | null
  checkoutFormConfig?: {
    main: ConfigInput
    authorizedSignatory: ConfigInput
  } | null
  authorizedSignatories?: (AvailableAuthorizedSignatory | null)[] | null
  deal?: InvestmentRequest_investmentRequest_deal | null
}

export type ContextValueType = {
  product: Product_product['deal']
  investmentRequest: InvestmentRequest
  updateInvestmentRequest: <T extends UpdateInvestmentRequestType>(
    data: T,
    currentStageIndex?: number
  ) => void
  shouldRefetchInvestmentRequest: boolean
  setShouldRefetchInvestmentRequest: Dispatch<SetStateAction<boolean>>
}

const configDefaultValue: Config = {
  stageTitles: [],
  stages: [],
  offset: 0,
  hasNext: true,
  totalStages: 0,
}

const defaultInvestmentRequest = {
  id: '',
  checkoutFormConfig: {
    main: configDefaultValue,
    authorizedSignatory: configDefaultValue,
  },
  status: InvestmentRequestStatus.DRAFT,
  coInvestmentStageName: null,
  authorizedSignatories: [],
  formData: {},
  interestRate: null,
  fundName: null,
}

export const CheckoutContext = createContext<ContextValueType>({
  product: null,
  investmentRequest: defaultInvestmentRequest,
  updateInvestmentRequest: () => {},
  shouldRefetchInvestmentRequest: false,
  setShouldRefetchInvestmentRequest: () => {},
})

const GET_PRODUCT = gql`
  query Product($dealId: String!, $language: Language!) {
    product(dealId: $dealId, language: $language) {
      deal {
        id
        name
        fundName
        previewImage
        hasDetailsPage
        introText
        currencyIsoCode
      }
    }
  }
`

export const CheckoutProvider = ({ children }: PropsWithChildren<{}>) => {
  const { productId } = useParams<{ productId: string }>()
  const { pathname } = useLocation()
  const { salesforceLanguage } = useTranslate()
  const [shouldRefetchInvestmentRequest, setShouldRefetchInvestmentRequest] = useState(false)
  const initialRender = useRef(true)

  const [product, setProduct] = useState<Product_product['deal']>(null)
  const [investmentRequest, setInvestmentRequest] = useState<InvestmentRequest>(defaultInvestmentRequest)

  const stageIndex = useRef(0)

  const [fetchProduct, { loading }] = useLazyQuery<Product>(GET_PRODUCT, {
    variables: {
      dealId: productId,
      language: salesforceLanguage(),
    },
    onCompleted: (data) => {
      if (data.product?.deal) {
        setProduct(data.product?.deal)
      }
    },
  })

  useEffect(() => {
    if (pathname === `${routes.CHECKOUT}/${productId}`) {
      ;(async () => {
        await fetchProduct()
      })()
    } else if (initialRender.current) {
      setShouldRefetchInvestmentRequest(true)
      initialRender.current = false
    }
  }, [fetchProduct, pathname, productId])

  const updateInvestmentRequest = useCallback(
    <T extends UpdateInvestmentRequestType>(data: T, newStageIndex: number = 0) => {
      const normalizedInvestmentRequest = normalizeInvestmentRequest(data)

      const toUpdateRequest = {
        ...investmentRequest,
        ...normalizedInvestmentRequest,
        fundName: normalizedInvestmentRequest?.deal?.fundName,
      } as InvestmentRequest

      if (data?.deal) {
        setProduct(data.deal)
      }
      setInvestmentRequest(toUpdateRequest)
      stageIndex.current = newStageIndex
    },
    [investmentRequest]
  )

  const contextValue = useMemo(
    () => ({
      product,
      updateInvestmentRequest,
      investmentRequest,
      shouldRefetchInvestmentRequest,
      setShouldRefetchInvestmentRequest,
    }),
    [product, updateInvestmentRequest, investmentRequest, shouldRefetchInvestmentRequest]
  )

  if (loading) {
    return <Loading />
  }

  return <CheckoutContext.Provider value={contextValue}>{children}</CheckoutContext.Provider>
}

export const useCheckout = () => useContext(CheckoutContext)
