import { useEffect } from 'react'

import { usePlatform } from './usePlatform'
import { useTranslate } from './useTranslate'

export const useLanguageSwitch = () => {
  const { isUK } = usePlatform()
  const { language, changeLanguage } = useTranslate()

  useEffect(() => {
    if (isUK && language !== 'en-GB') {
      changeLanguage('en-GB')
    } else if (!isUK && language === 'en-GB') {
      changeLanguage('en')
    }
  }, [changeLanguage, isUK, language])

  return null
}
