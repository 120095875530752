import React, { PropsWithChildren } from 'react'

import { ThemeProvider, ToastProvider } from '@linus-capital/linus-ui'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { NestedComposer } from 'components/NestedComposer'
import { ApolloProvider } from 'contexts/ApolloProvider'
import { UserProvider } from 'contexts/UserContext'
import { IdVerificationProvider } from 'pages/IdVerification/IdVerificationContext'
import { InvestorIdentificationProvider } from 'pages/InvestorIdentification/InvestorIdentificationProvider'
import { I18nextProvider } from 'react-i18next'
import i18n from 'services/i18n'

type Props = PropsWithChildren<{}>

const components: React.JSXElementConstructor<any>[] = [
  ThemeProvider,
  ErrorBoundary,
  ApolloProvider,
  ToastProvider,
]

const privateComponents: React.JSXElementConstructor<any>[] = [
  UserProvider,
  InvestorIdentificationProvider,
  IdVerificationProvider,
]

export const BaseProviders = ({ children }: Props) => (
  <I18nextProvider i18n={i18n}>
    <NestedComposer components={components}>{children}</NestedComposer>
  </I18nextProvider>
)

export const PrivateProviders = ({ children }: Props) => (
  <NestedComposer components={privateComponents}>{children}</NestedComposer>
)

const AppProviders = ({ children }: Props) => (
  <I18nextProvider i18n={i18n}>
    <NestedComposer components={[...components, ...privateComponents]}>{children}</NestedComposer>
  </I18nextProvider>
)

export default AppProviders
