import { useCallback, useRef } from 'react'

import { useUser } from 'contexts/UserContext'
import { useConfiguration } from 'hooks/useConfiguration'
import { useLocation } from 'react-router-dom'
import { RedirectRules } from 'utils/redirectRules'

export const useRedirect = () => {
  const { user: marketplaceUser, isClassification } = useUser()
  const { pathname } = useLocation()
  const { isSecretUser } = useConfiguration()
  const calledOnce = useRef(false)

  const getRedirectPath = useCallback(() => {
    if (calledOnce.current || (!marketplaceUser && !isSecretUser)) {
      return
    }

    calledOnce.current = true

    const rule = RedirectRules.find((rule) =>
      rule.conditions(marketplaceUser ?? null, {
        pathname,
        isClassification,
        isSecretUser,
      })
    )

    return rule?.path
  }, [marketplaceUser, pathname, isClassification, isSecretUser])

  return getRedirectPath()
}
