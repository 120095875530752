import { useMemo } from 'react'

import { useQuery } from 'hooks/useQuery'
import { useLocation } from 'react-router-dom'
import { isPublicPath, isTest, routes } from 'utils/constants'
import { findLocalItems } from 'utils/stringUtils'

export const useConfiguration = () => {
  const location = useLocation()
  const isSecretUserParam = useQuery().get('isSecretUser') as string
  const isPublic = useMemo(() => isPublicPath(location.pathname), [location.pathname])

  // START isMarketUserUnavailable
  // Route is Public and the account has not been created yet
  const auth0Token = findLocalItems(/^@@auth0spajs@@::/)
  const isPublicRoute = !isTest && isPublic && !auth0Token

  // Secret Link Route
  const isSecretLinkRoute = useMemo(() => location.pathname.includes(routes.SECRET_LINK), [location.pathname])
  const isSecretUser = () => {
    if (isTest && isSecretUserParam === 'true') return true
    const auth0TokenValue = window.localStorage.getItem(auth0Token ?? '')
    return auth0TokenValue?.includes('http://secretLinkUser/grantedResources')
  }
  const isMarketUserUnavailable = isPublicRoute || isSecretLinkRoute || isSecretUser()
  // END isMarketUserUnavailable

  return { isPublic, isMarketUserUnavailable, isSecretUser: !!isSecretUser() }
}
