import React, { createContext, ReactNode, useContext, useState } from 'react'

import {
  ApolloClient,
  ApolloProvider as OriginalApolloProvider,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { useAuth0 } from '@auth0/auth0-react'
import { environment, routes } from 'utils/constants'

type ContextType = {
  setTokenRefetch: () => void
  allowTokenRefetch?: boolean
}

const ApolloContext = createContext<ContextType>({
  setTokenRefetch: () => {},
  allowTokenRefetch: false,
})

export const cache = new InMemoryCache()

export const useApolloProvider = () => useContext(ApolloContext)

export const ApolloProvider = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0()

  const [allowTokenRefetch, setAllowTokenRefetch] = useState(false)

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
  })

  const authLink = setContext(async () => {
    let authorization = 'none'
    if (isAuthenticated || allowTokenRefetch) {
      authorization = `Bearer ${await getAccessTokenSilently({})}`
    }

    return {
      headers: {
        Authorization: authorization,
      },
    }
  })

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err?.extensions.code) {
          case 'UNAUTHENTICATED':
            logout({ returnTo: `${window.location.origin}${routes.LOGIN}` })
            break
        }
      }
    }
  })

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache,
    connectToDevTools: process.env.REACT_APP_ENVIRONMENT === environment.DEVELOPMENT,
    name: `bricks-frontend-${process.env.REACT_APP_ENVIRONMENT}`,
    version: process.env.REACT_APP_VERSION,
  })

  const handleTokenRefetch = () => {
    setAllowTokenRefetch(true)
  }

  return (
    <ApolloContext.Provider
      value={{
        setTokenRefetch: handleTokenRefetch,
        allowTokenRefetch,
      }}
    >
      <OriginalApolloProvider client={apolloClient}>{children}</OriginalApolloProvider>
    </ApolloContext.Provider>
  )
}
