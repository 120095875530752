type ParamProps = {
  utm_source?: string
  utm_medium?: string
  utm_term?: string
  utm_content?: string
  utm_campaign?: string
}

export function buildQueryString(params: ParamProps) {
  return Object.keys(params)
    .reduce((cur: string, acc: string) => {
      const value = params[acc as keyof ParamProps]
      return value ? `${cur}&${acc}=${encodeURIComponent(value)}` : ''
    }, '')
    .slice(1)
}

export const findLocalItems = (query: RegExp) => {
  for (const i in window.localStorage) {
    // eslint-disable-next-line no-prototype-builtins
    if (window.localStorage.hasOwnProperty(i) && i.match(query)) {
      return i
    }
  }
  return null
}

export const parseJSONString = <T>(value: string): T | null => {
  try {
    return JSON.parse(value) as T
  } catch {
    return null
  }
}
