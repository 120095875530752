import React, { Suspense } from 'react'

import { Auth0Provider, AppState } from '@auth0/auth0-react'
import { LoadingPage } from 'components/Loading'
import { ScrollToTop } from 'components/ScrollToTop'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { BrowserRouter as Router } from 'react-router-dom'
import { initSentry } from 'services/sentry'

import App, { history } from './App'

initSentry(history)

const onRedirectCallback = (appState?: AppState) => {
  history.replace((appState && appState.returnTo) || window.location.pathname)
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
      clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
      redirectUri={`${window.location.origin}/auth-callback`}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope={process.env.REACT_APP_AUTH0_SCOPE}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      advancedOptions={{ defaultScope: '' }}
    >
      <Suspense fallback={<LoadingPage />}>
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </Suspense>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

TagManager.initialize({ gtmId: 'GTM-N33RQ4P' })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
