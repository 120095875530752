import { gql } from '@apollo/client'

export const CREATE_IDENT_CASE = gql`
  mutation CreateIdentCase($identCaseType: IdentCaseType!, $personData: PersonDataInput!) {
    createIdentCase(identCaseType: $identCaseType, personData: $personData)
  }
`

export const CREATE_IDENT_CASE_TRANSACTION = gql`
  mutation CreateIdentCaseTransaction(
    $identCaseId: ID!
    $identCaseTransactionType: IdentCaseTransactionType!
  ) {
    createIdentCaseTransaction(identCaseId: $identCaseId, identCaseTransactionType: $identCaseTransactionType)
  }
`

export const IDENT_CASES = gql`
  query IdentCases($filter: IdentCaseFilter) {
    identCases(filter: $filter) {
      id
      type
      status
      creationDate
      currentTransaction {
        status
        type
        id
        identRedirectUrl
      }
    }
  }
`
