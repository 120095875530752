import { gql } from '@apollo/client'
import { Platform } from 'utils/constants'

export const GET_USER_UK = gql`
  query MarketplaceUserUK {
    marketplaceUser {
      id
      email
      salutation
      firstName
      lastName
      phone
      newsletter
      language
      company
      ndaAcceptedAt
      platformAccess
      termsConditionsAcceptedAt
      onboardingCall {
        startTime
        endTime
      }
      accountManager {
        id
        email
        firstName
        lastName
        title
        imageUrl
        profileId
        mobilePhone
        calendlyLinkTemplate
        signatureImageUrl
      }
      joinedNewDealsWaitingList
      ukInvestorClassification
      acceptedUkRiskWarning
      ndaAccepted
      termsConditionsAccepted
      contactDetailsSubmitted
      welcomeMessageConfirmed
      status
      hasOptedOutOfEmail
      registrationStatus
      source
      invitationStatus
      fallbackRegistrationStatus
      ukClassificationFormConfig {
        stages
        stageTitles
      }
      ukClassificationFormData
      ukClassificationSubmitted
      platform
    }
  }
`

export const GET_USER_EU = gql`
  query MarketplaceUserEU {
    marketplaceUser {
      id
      email
      salutation
      firstName
      lastName
      phone
      newsletter
      language
      company
      ndaAcceptedAt
      platformAccess
      termsConditionsAcceptedAt
      onboardingCall {
        startTime
        endTime
      }
      accountManager {
        id
        email
        firstName
        lastName
        title
        imageUrl
        profileId
        mobilePhone
        calendlyLinkTemplate
        signatureImageUrl
      }
      joinedNewDealsWaitingList
      ukInvestorClassification
      acceptedUkRiskWarning
      ndaAccepted
      termsConditionsAccepted
      contactDetailsSubmitted
      welcomeMessageConfirmed
      status
      hasOptedOutOfEmail
      registrationStatus
      source
      invitationStatus
      fallbackRegistrationStatus
      platform
    }
  }
`

export const GET_USER = {
  [Platform.EU]: GET_USER_EU,
  [Platform.UK]: GET_USER_UK,
}

export const GET_USER_ONBOARDING_CALL = gql`
  query MarketplaceUserOnboardingCall {
    marketplaceUser {
      onboardingCall {
        startTime
        endTime
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateAccountDetails($update: UpdateAccountDetailsGraphQLInput, $deleteItems: [String]) {
    updateAccountDetails(update: $update, deleteItems: $deleteItems)
  }
`

export const CONFIRM_WELCOME_MESSAGE = gql`
  mutation ConfirmWelcomeMessage {
    confirmWelcomeMessage
  }
`

export const ACCEPT_NDA_AND_TERMS = gql`
  mutation AcceptNdaAndTerms {
    acceptNdaAndTerms
  }
`

export const JOIN_DEALS_WAITING_LIST = gql`
  mutation JoinNewDealsWaitingList {
    joinNewDealsWaitingList
  }
`
