import React, { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from 'components/Loading'
import { usePlatform } from 'hooks/usePlatform'
import { useQuery } from 'hooks/useQuery'
import { useTranslate } from 'hooks/useTranslate'
import { getRedirectUrl } from 'utils/pathUtils'

export const Login = () => {
  const { loginWithRedirect } = useAuth0()
  const { language } = useTranslate()
  const { platform } = usePlatform()
  const prompt = useQuery().get('prompt')

  useEffect(() => {
    loginWithRedirect({
      redirect_uri: getRedirectUrl(language),
      fragment: encodeURIComponent(JSON.stringify({ platform, isRelaunchUser: true })),
      prompt: prompt === 'none' ? prompt : undefined,
    })
  }, [language, loginWithRedirect, platform, prompt])

  return <Loading />
}
