import { FormData } from 'types/CheckoutFormData'
import { Stage } from 'types/Stages'
import { parseJSONString } from 'utils/stringUtils'

import { UpdateInvestmentRequestType, ConfigInput } from './CheckoutProvider'

const toConfig = (config: ConfigInput) => ({
  ...config,
  stages: parseJSONString(config?.stages!) as Stage[],
})

const toFormConfig = (checkoutConfig?: UpdateInvestmentRequestType['checkoutFormConfig']) => {
  if (checkoutConfig) {
    return {
      checkoutFormConfig: {
        main: toConfig(checkoutConfig?.main),
        authorizedSignatory: toConfig(checkoutConfig?.authorizedSignatory),
      },
    }
  }
  return {}
}

export const normalizeInvestmentRequest = (data?: UpdateInvestmentRequestType | null) =>
  data
    ? {
        ...data,
        formData: parseJSONString(data?.formData!) as FormData,
        ...toFormConfig(data?.checkoutFormConfig),
      }
    : null
