import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react'

export enum IdentificationProvider {
  POST_IDENT = 'postIdent',
  POST_IDENT_QES = 'postIdentQes',
  ID_NOW = 'idNow',
  ID_NOW_QES = 'idNowQes',
  ID_NOW_AUTO = 'idNowAuto',
}

export type PersonData = {
  firstName: string
  lastName: string
  birthDate: string
  email: string
}

export type IdentificationStatus = {
  inProgress: boolean
  isWindowExist: boolean
  clickOutURL?: string
}

type InvestorIdentificationContextType = {
  selectedInvestorIndex?: number | undefined
  setSelectedInvestorIndex?: Dispatch<SetStateAction<number | undefined>>
  provider?: IdentificationProvider
  setProvider?: Dispatch<SetStateAction<IdentificationProvider | undefined>>
  setClickOutWindowRef?: Dispatch<SetStateAction<Window | null>>
  clickOutWindowRef?: Window | null
  identificationStatus?: IdentificationStatus
  setIdentificationStatus?: Dispatch<SetStateAction<IdentificationStatus>>
  reset?: () => void
}

const InvestorIdentificationContext = createContext<InvestorIdentificationContextType>({})

export const useInvestorIdentificationContext = () => useContext(InvestorIdentificationContext)

type InvestorIdentificationContextProps = PropsWithChildren<{}>

export const InvestorIdentificationProvider = ({ children }: InvestorIdentificationContextProps) => {
  const [selectedInvestorIndex, setSelectedInvestorIndex] = useState<number | undefined>()
  const [provider, setProvider] = useState<IdentificationProvider | undefined>(
    IdentificationProvider.ID_NOW_AUTO
  )
  const [clickOutWindowRef, setClickOutWindowRef] = useState<Window | null>(null)

  const [identificationStatus, setIdentificationStatus] = useState<IdentificationStatus>({
    inProgress: false,
    isWindowExist: false,
  })

  const reset = useCallback(() => {
    setProvider(undefined)
    setSelectedInvestorIndex(undefined)
    setClickOutWindowRef(null)
  }, [setProvider, setSelectedInvestorIndex, setClickOutWindowRef])

  return (
    <InvestorIdentificationContext.Provider
      value={{
        selectedInvestorIndex,
        setSelectedInvestorIndex,
        provider,
        setProvider,
        clickOutWindowRef,
        setClickOutWindowRef,
        setIdentificationStatus,
        identificationStatus,
        reset,
      }}
    >
      {children}
    </InvestorIdentificationContext.Provider>
  )
}
