import React, { createContext, useContext, useEffect, useRef } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Loading } from 'components/Loading'
import { useConfiguration } from 'hooks/useConfiguration'
import { usePlatform } from 'hooks/usePlatform'
import { useNavigate } from 'react-router-dom'
import { logError } from 'services/sentry'
import { SalesforceUserStatus } from 'types/graphql-global-types'
import { domains, isDevelop, isTest, Platform, routes } from 'utils/constants'

import { MarketplaceUserEU_marketplaceUser } from './gql-query-types/MarketplaceUserEU'
import { MarketplaceUserUK_marketplaceUser } from './gql-query-types/MarketplaceUserUK'
import { GET_USER } from './gqls'

export type MarketplaceUser = (MarketplaceUserEU_marketplaceUser & MarketplaceUserUK_marketplaceUser) | null

type MarketplaceUserData = {
  marketplaceUser: MarketplaceUser
}

type UserContextType = {
  user?: MarketplaceUser | null
  refetch: () => void
  isClassification: boolean
  shouldRefetchOpenAllocations: boolean
  setShouldRefetchOpenAllocations: (value: boolean) => void
}

const UserContext = createContext<UserContextType>({
  user: null,
  refetch: () => {},
  isClassification: false,
  shouldRefetchOpenAllocations: false,
  setShouldRefetchOpenAllocations: () => {},
})

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { isMarketUserUnavailable } = useConfiguration()
  const { platform, isUK } = usePlatform()
  const isNotTestOrDevelopment = !(isTest || isDevelop)
  const navigate = useNavigate()
  const shouldRefetchOpenAllocations = useRef(false)

  const [fetchUser, { loading, data, refetch }] = useLazyQuery<MarketplaceUserData>(GET_USER[platform], {
    onCompleted: ({ marketplaceUser }) => {
      const userPlatform = marketplaceUser?.platform?.toLowerCase()
      if (isNotTestOrDevelopment && userPlatform !== platform) {
        window.location.replace(
          `${domains[process.env.REACT_APP_ENVIRONMENT!][userPlatform as Platform]}${
            routes.LOGIN
          }?prompt=none`
        )
      }
    },
    onError: (error) => {
      logError(error)
      navigate(routes.LOGOUT)
    },
  })

  const setShouldRefetchOpenAllocations = (value: boolean) => {
    shouldRefetchOpenAllocations.current = value
  }

  useEffect(() => {
    if (isMarketUserUnavailable) {
      return
    }
    ;(async () => {
      await fetchUser()
    })()
  }, [fetchUser, isMarketUserUnavailable])

  const isClassification =
    isUK &&
    (!data?.marketplaceUser?.ukClassificationSubmitted ||
      data?.marketplaceUser?.status === SalesforceUserStatus.JOIN)

  if (loading) {
    return <Loading />
  }

  return (
    <UserContext.Provider
      value={{
        user: data?.marketplaceUser,
        refetch,
        isClassification,
        shouldRefetchOpenAllocations: shouldRefetchOpenAllocations.current,
        setShouldRefetchOpenAllocations,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
