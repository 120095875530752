import { useEffect } from 'react'

import { Action, useActionExecutor } from './useActionExecutor'

export const ActionsHandler = () => {
  const { actionExecute } = useActionExecutor()

  useEffect(() => {
    const handleAction = (event: CustomEvent<Action>) => {
      const { detail } = event
      actionExecute(detail)
    }
    window.document.addEventListener('builderAction', handleAction as (e: Event) => void)
    return () => {
      window.document.removeEventListener('builderAction', handleAction as (e: Event) => void)
    }
  }, [actionExecute])

  return null
}
