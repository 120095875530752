import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { History } from 'history'

const isLocal = process.env.NODE_ENV === 'development'

export const initSentry = (history: History) => {
  if (isLocal) {
    return
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history as any),
      }),
    ],
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracesSampleRate: Number.parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE ?? '1.0'),
    autoSessionTracking: true,
  })
}

export function logError(error: Error, errorInfo = null) {
  if (isLocal) {
    console.error(error)
    return
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo)
    Sentry.captureException(error)
  })
}
