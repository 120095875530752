import React, { PropsWithChildren } from 'react'

import { Flex, Spinner, colors } from '@linus-capital/linus-ui'

type Props = {
  height?: string
  spinnerSize?: string
}

export const LoadingPage = ({ children, height = '100vh' }: PropsWithChildren<Props>) => (
  <Flex alignItems="center" justifyContent="center" minHeight={height} backgroundColor={colors.background}>
    {children}
  </Flex>
)

export const Loading = ({ height, spinnerSize = 'xxlarge' }: Props) => (
  <LoadingPage height={height}>
    <Spinner size={spinnerSize} />
  </LoadingPage>
)
