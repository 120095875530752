import { ComponentType } from 'react'

import {
  eachDayOfInterval,
  addBusinessDays,
  addDays,
  isWeekend,
  lastDayOfMonth,
  addMonths,
  differenceInBusinessDays,
  format,
} from 'date-fns'
import countries from 'i18n-iso-countries'

import { isGermanHoliday } from './germanHoliday'

export const getInvestmentDateOptions = (locale = 'de-DE', limit = 5) => {
  const currentDate = new Date()
  let monthlyStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 15)
  const twoDaysAfter = addDays(currentDate, 2)
  if (twoDaysAfter.getMonth() !== currentDate.getMonth()) {
    monthlyStartDate = addMonths(monthlyStartDate, 1)
  }

  if (twoDaysAfter.getDate() > monthlyStartDate.getDate()) {
    monthlyStartDate = lastDayOfMonth(twoDaysAfter)
  }

  const results = eachDayOfInterval({
    start: monthlyStartDate,
    end: new Date(`${currentDate.getFullYear() + 1}-12-31T00:00:00Z`),
  }).filter((date, idx) => idx === 0 || [15, lastDayOfMonth(date).getDate()].includes(date.getDate()))

  return results
    .map((date) => {
      if (isWeekend(date)) {
        return addBusinessDays(date, -1)
      }
      return date
    })
    .filter((date) => !isGermanHoliday(date) && differenceInBusinessDays(date, currentDate) >= 2)
    .map((date) => ({
      label: date.toLocaleString(locale, {
        weekday: 'long',
        day: 'numeric',
        month: '2-digit',
        year: 'numeric',
      }),
      value: format(date, 'yyyy-MM-dd'),
    }))
    .slice(0, limit)
}

// conditionally loading the countries
export const getCountryNames = (lang: string) => {
  countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
  if (lang === 'en') {
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
  }

  return countries.getNames(lang === 'en' ? 'en' : 'de')
}

export const partition = <T>(arr: T[], predicate: (value: T) => boolean) =>
  arr.reduce(
    (acc, cur) => {
      acc[predicate(cur) ? 0 : 1].push(cur)
      return acc
    },
    [[], []] as T[][]
  )

export const isCalendlyEvent: (e: MessageEvent) => boolean = (e) =>
  e.data.event && e.data.event.indexOf('calendly') === 0

export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

export const safeClearTimeout = (timerId: ReturnType<typeof setTimeout> | null) => {
  if (timerId) {
    clearTimeout(timerId)
  }
}

export const safeParseInt = (value: string | number): number =>
  typeof value === 'number' ? value : parseInt(value, 10)

export const pick = <T extends {}>(obj: T, keys: string[]) =>
  Object.keys(obj)
    .filter((k) => keys.includes(k))
    .reduce((res, k) => Object.assign(res, { [k]: obj[k as keyof T] }), {} as T)

export const lazyRetry = (
  componentName: string,
  componentImport: () => Promise<{}>
): Promise<{ [key: string]: ComponentType }> =>
  new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${componentName}-refreshed`) || 'false'
    )
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${componentName}-refreshed`, 'false')
        resolve(component)
      })
      .catch((error: unknown) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${componentName}-refreshed`, 'true')
          return window.location.reload()
        }
        reject(error) // Default error behavior as already tried refresh
      })
  })
