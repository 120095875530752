import { useRef, useState } from 'react'

import { DocumentNode, OperationVariables, useLazyQuery } from '@apollo/client'
import { logError } from 'services/sentry'
import { safeClearTimeout } from 'utils/misc'

export const useQueryWithPolling = <T>(
  query: DocumentNode,
  variables?: OperationVariables,
  pollingExitCheck?: (data: T) => boolean,
  pollInterval = 0,
  timeout = 0,
  onError?: (error: Error) => void,
  onLoaded?: (data: T) => void
) => {
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null)

  const [loading, setLoading] = useState<boolean | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)
  const [result, setResult] = useState<T | undefined>()

  const [fetch, { startPolling, stopPolling, refetch }] = useLazyQuery<T>(query, {
    variables,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!pollingExitCheck || pollingExitCheck(data)) {
        stopPolling()
        setLoading(false)
        setResult(data)
        safeClearTimeout(timerId.current)
        onLoaded?.(data)
      }
    },
    onError: (error) => {
      logError(error)
      setLoading(false)
      setError(error)
      safeClearTimeout(timerId.current)
      onError?.(error)
    },
  })

  const fireTimeout = () => {
    // TODO: create custom error
    stopPolling()
    const error = new Error('Timeout')
    logError(error)
    setError(error)
    onError?.(error)
  }

  const poll = () => {
    setLoading(true)
    if (timeout) {
      timerId.current = setTimeout(fireTimeout, timeout)
    }
    startPolling(pollInterval)
  }

  return { result, loading, error, poll, stopPolling, refetch, fetch }
}
