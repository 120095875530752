import React, { PropsWithChildren, useCallback } from 'react'

import { Box, Button, Flex, H3, Text } from '@linus-capital/linus-ui'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { useTranslate } from 'hooks/useTranslate'

export const ErrorBoundary = ({ children }: PropsWithChildren<{}>) => {
  const { translate } = useTranslate()

  const handleReload = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <SentryErrorBoundary
      fallback={
        <Box minHeight="100vh" bg={'background'}>
          <Flex flexDirection={'column'} justifyContent="center" alignItems="center" minHeight="100vh">
            <H3>{translate('error.boundary.title')}</H3>
            <Text my={3}>{translate('error.boundary.subTitle')}</Text>
            <Button onClick={handleReload} variant="text">
              {translate('error.boundary.cta')}
            </Button>
          </Flex>
        </Box>
      }
    >
      {children}
    </SentryErrorBoundary>
  )
}
