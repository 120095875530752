import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'

import { useMutation, resetCaches, useLazyQuery } from '@apollo/client'
import { useQueryWithPolling } from 'hooks/useQueryWithPolling'
import { logError } from 'services/sentry'
import { IdentCaseTransactionStatus, IdentCaseType, PersonDataInput } from 'types/graphql-global-types'
import { mapIdentproviderToCaseType, mapIdentproviderToTransactionType } from 'utils/mappingUtils'

import {
  IdentificationProvider,
  IdentificationStatus,
  PersonData,
  useInvestorIdentificationContext,
} from '../InvestorIdentification/InvestorIdentificationProvider'
import { CreateIdentCase, CreateIdentCaseVariables } from './gql-query-types/CreateIdentCase'
import { CreateIdentCaseTransaction } from './gql-query-types/CreateIdentCaseTransaction'
import { IdentCases, IdentCases_identCases } from './gql-query-types/IdentCases'
import { CREATE_IDENT_CASE, CREATE_IDENT_CASE_TRANSACTION, IDENT_CASES } from './gqls'

const WINDOW_CLOSE_CAPTURE_INTERVAL = 1000

export enum StatusMessage {
  ABORTED,
  SYSTEM_FAILED,
  IDENTIFICATION_FAILED,
  SUCCESS,
  IDENTIFICATION_START_FAILED,
  NEW_IDENTIFICATION_STARTED,
}

export type UpdaterCallback = (statusMessage: StatusMessage) => void

type IdVerificationContextType = {
  startIdVerification?: (url: string) => void
  cancelIdVerification?: () => void
  identificationStatus?: IdentificationStatus
  createTransaction?: (personData: PersonDataInput) => Promise<void>
  identCaseId?: string
  identCase?: IdentCases_identCases
  provider?: IdentificationProvider
  setIdentCaseId?: (id: string) => void
  setIdentCase?: React.Dispatch<React.SetStateAction<IdentCases_identCases>>
  setProvider?: React.Dispatch<React.SetStateAction<IdentificationProvider | undefined>>
  setBlock?: React.Dispatch<React.SetStateAction<boolean>>
  poll?: () => void
  updateStatus?: (updater: (statusMessage: StatusMessage) => void) => void
  isFetchingUrl?: boolean
  setFetchingUrl?: Dispatch<React.SetStateAction<boolean>>
  personData?: PersonData
  setPersonData?: Dispatch<React.SetStateAction<PersonData>>
  marketplaceUserId?: string
  setMarketplaceUserId?: Dispatch<React.SetStateAction<string>>
  handleStartIdVerification?: (data: PersonData) => void
  startOrContinueIdent?: (id: string, provider?: IdentificationProvider) => void
}

const IdVerificationContext = createContext<IdVerificationContextType>({
  identificationStatus: { inProgress: false, isWindowExist: false },
})

export const useIdVerificationContext = () => useContext(IdVerificationContext)

type IdVerificationProviderProps = PropsWithChildren<{}>

export const IdVerificationProvider = ({ children }: IdVerificationProviderProps) => {
  const updaterRef = useRef<UpdaterCallback | null>(null)
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null)

  const [isFetchingUrl, setFetchingUrl] = useState(false)
  const [personData, setPersonData] = useState<PersonData>({
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
  })
  const {
    provider,
    setProvider,
    clickOutWindowRef,
    setClickOutWindowRef,
    identificationStatus,
    setIdentificationStatus,
  } = useInvestorIdentificationContext()

  const [identCase, setIdentCase] = useState<IdentCases_identCases>({} as IdentCases_identCases)
  const [identCaseId, setIdentCaseId] = useState<string>('')

  const [createIdentCase] = useMutation<CreateIdentCaseVariables>(CREATE_IDENT_CASE)

  const createIdNowWindow = useCallback(
    (url: string) => {
      if (!clickOutWindowRef || clickOutWindowRef?.closed) {
        setClickOutWindowRef?.(window.open(url, '_blank'))
      }
    },
    [clickOutWindowRef, setClickOutWindowRef]
  )

  const startIdVerification = useCallback(
    (url: string) => {
      if (url) {
        createIdNowWindow(url)
        setIdentificationStatus?.({
          inProgress: true,
          isWindowExist: !!clickOutWindowRef,
          clickOutURL: url,
        })
        //startPullTimer()
      }
    },
    [clickOutWindowRef, createIdNowWindow, setIdentificationStatus]
  )

  const createTransaction = useCallback(
    async (personData: PersonDataInput) => {
      await createIdentCase({
        variables: { identCaseType: IdentCaseType.LINUS, personData },
      })
    },
    [createIdentCase]
  )

  const { poll: pollStatus, stopPolling: stopPollingStatus } = useQueryWithPolling<IdentCases>(
    IDENT_CASES,
    { filter: { ids: [identCase?.id ?? identCaseId] } },
    (data) => {
      if (data?.identCases?.length > 0) {
        const status = data.identCases[0]?.currentTransaction?.status
        switch (status) {
          case IdentCaseTransactionStatus.ABORTED:
            // user - canceled ident - show aborted info message
            updaterRef.current?.(StatusMessage.ABORTED)
            stopPollingStatus()
            break
          case IdentCaseTransactionStatus.FAILED_TO_START:
            // error - our backend failed - just show error - no retry
            stopPollingStatus()
            updaterRef.current?.(StatusMessage.SYSTEM_FAILED)
            break
          case IdentCaseTransactionStatus.FAILED:
            // error - this after identification - failed identification
            updaterRef.current?.(StatusMessage.IDENTIFICATION_FAILED)
            stopPollingStatus()
            break
          case IdentCaseTransactionStatus.SUCCESS:
            // success - show success message - update UI
            cancelIdVerification()
            updaterRef.current?.(StatusMessage.SUCCESS)
            break
          case IdentCaseTransactionStatus.CREATED:
            // won't happen in this poll - let's keep it for error tracking - add GA event
            break
          case IdentCaseTransactionStatus.PENDING:
          case IdentCaseTransactionStatus.STARTED:
            // ignore - continue polling - let's keep it for time or error tracking - add GA events
            break
        }
      }
      return false
    },
    5000,
    20 * 60 * 1000, // polling for 20 mins
    (error) => {
      logError(error)
    }
  )

  const [fetchIdentCase] = useLazyQuery<IdentCases>(IDENT_CASES, {
    variables: { filter: { ids: [identCase?.id ? identCase?.id : identCaseId] } },
    fetchPolicy: 'no-cache',
    onCompleted: (data: IdentCases) => {
      setIdentCase(data?.identCases[0])
      updaterRef.current?.(StatusMessage.NEW_IDENTIFICATION_STARTED)
      const identRedirectUrl = data?.identCases[0]?.currentTransaction?.identRedirectUrl
      if (identRedirectUrl && !identificationStatus?.inProgress) {
        setTimeout(() => {
          startIdVerification?.(identRedirectUrl)
          pollStatus?.()
        })
      }
    },
    onError: (error) => {
      logError(error)
      setFetchingUrl?.(false)
      updaterRef.current?.(StatusMessage.IDENTIFICATION_START_FAILED)
    },
  })

  const { poll, stopPolling } = useQueryWithPolling<IdentCases>(
    IDENT_CASES,
    { filter: { ids: [provider && identCase?.id ? identCase?.id : identCaseId] } },
    (data) => {
      if (data?.identCases?.length > 0) {
        const identRedirectUrl = data.identCases[0]?.currentTransaction?.identRedirectUrl
        if (
          identRedirectUrl &&
          mapIdentproviderToTransactionType(provider) == data.identCases[0]?.currentTransaction?.type
        ) {
          stopPolling()
          setTimeout(() => {
            startIdVerification?.(identRedirectUrl)
            pollStatus()
          })
          return true
        }
      }
      return false
    },
    2000,
    30 * 1000, // polling for URL
    (error) => {
      logError(error)
      cancelIdVerification()
    }
  )

  const cancelIdVerification = useCallback(() => {
    setIdentificationStatus?.({ inProgress: false, isWindowExist: false })
    stopPolling()
    stopPollingStatus()
    setFetchingUrl(false)
    setIdentCase({} as IdentCases_identCases)
    try {
      clickOutWindowRef?.close()
      setClickOutWindowRef?.(null)
      if (timerId.current) {
        clearInterval(timerId.current)
      }
    } catch (error) {
      logError(error as Error)
    }
  }, [stopPolling, stopPollingStatus, clickOutWindowRef, setClickOutWindowRef, setIdentificationStatus])

  const updateStatus = useCallback((updater: UpdaterCallback) => {
    updaterRef.current = updater
  }, [])

  const [startIdentCaseTransaction] = useMutation<CreateIdentCaseTransaction>(CREATE_IDENT_CASE_TRANSACTION, {
    variables: {
      identCaseId: identCase?.id ?? identCaseId,
      identCaseTransactionType: mapIdentproviderToTransactionType(provider),
    },
    onError: (error) => {
      logError(error)
      setFetchingUrl?.(false)
      updaterRef.current?.(StatusMessage.IDENTIFICATION_START_FAILED)
    },
  })

  const [fetchIdentCaseId] = useMutation<CreateIdentCase>(CREATE_IDENT_CASE, {
    onCompleted: async (data) => {
      setIdentCaseId?.(data.createIdentCase)
      await startOrContinueIdent(data.createIdentCase, provider)
    },
    onError: (error) => {
      logError(error)
      setFetchingUrl?.(false)
      updaterRef.current?.(StatusMessage.IDENTIFICATION_START_FAILED)
    },
  })

  const handleStartIdVerification = useCallback(
    async (data: PersonData) => {
      setFetchingUrl?.(true)
      setPersonData(data)

      await fetchIdentCaseId({
        variables: {
          identCaseType: mapIdentproviderToCaseType(provider),
          personData: data,
        },
      })
    },
    [fetchIdentCaseId, provider]
  )

  const startOrContinueIdent = useCallback(
    async (id, provider) => {
      resetCaches()
      setFetchingUrl?.(true)

      if (
        identCase?.currentTransaction &&
        identCase?.currentTransaction?.status === IdentCaseTransactionStatus.STARTED &&
        mapIdentproviderToTransactionType(provider) === identCase.currentTransaction.type
      ) {
        if (identCase.currentTransaction.identRedirectUrl) {
          startIdVerification(identCase.currentTransaction.identRedirectUrl)
          setIdentCaseId(id)
          setProvider?.(provider)
          pollStatus?.()
        }
      } else {
        await startIdentCaseTransaction({
          variables: {
            identCaseId: id,
            identCaseTransactionType: mapIdentproviderToTransactionType(provider),
          },
          onCompleted: () => {
            setTimeout(() => {
              fetchIdentCase({
                variables: { filter: { ids: [id] } },
              })
            }, 3000)
          },
        })
      }
    },
    [
      startIdentCaseTransaction,
      fetchIdentCase,
      setProvider,
      identCase,
      startIdVerification,
      pollStatus,
      setIdentCaseId,
    ]
  )

  return (
    <IdVerificationContext.Provider
      value={{
        startIdVerification,
        cancelIdVerification,
        identificationStatus,
        createTransaction,
        identCaseId,
        setIdentCaseId,
        setProvider,
        setIdentCase,
        poll,
        updateStatus,
        isFetchingUrl,
        setFetchingUrl,
        personData,
        setPersonData,
        handleStartIdVerification,
        startOrContinueIdent,
      }}
    >
      {children}
    </IdVerificationContext.Provider>
  )
}
