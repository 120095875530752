import { addDays, isEqual } from 'date-fns'

/**
 * Calculates Easter in the Gregorian/Western (Catholic and Protestant) calendar
 * based on the algorithm by Oudin (1940) from http://www.tondering.dk/claus/cal/easter.php
 * Found the solution here https://gist.github.com/johndyer/0dffbdd98c2046f41180c051f378f343
 * @param {number} year - The year to calculate the easter date for
 * @returns {Date} Easter date for the year
 */
export const getEaster = (year: number): Date => {
  const f = Math.floor
  // Golden Number - 1
  const G = year % 19
  const C = f(year / 100)
  // related to Epact
  const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30
  // number of days from 21 March to the Paschal full moon
  const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11))
  // weekday for the Paschal full moon
  const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7
  // number of days from 21 March to the Sunday on or before the Paschal full moon
  const L = I - J
  const month = 3 + f((L + 40) / 44)
  const day = L + 28 - 31 * f(month / 4)

  return new Date(year, month - 1, day)
}

export const getHolidays = () => {
  const monthAndDay = ['01.01', '05.01', '10.03', '12.25', '12.26']

  const currentYear = new Date().getFullYear()
  const easter = getEaster(currentYear)
  const goodFriday = addDays(easter, -2)
  const easterMonday = addDays(easter, 1)
  /*
   * Pentecost is the Sunday after the full moon of Easter
   * https://en.wikipedia.org/wiki/Pentecost (in German)
   * WhitMonday is the Monday after Pentecost
   */
  const whitMonday = addDays(easter, 50)

  const holidays = monthAndDay.map((holiday) => new Date(`${holiday}.${currentYear}`))

  return [...holidays, whitMonday, easter, easterMonday, goodFriday]
}

/**
 * @param {Date|string} date - The date to check
 * @returns {Boolean}
 */
export const isGermanHoliday = (date: Date | string): boolean => {
  const holidays = getHolidays()
  return !!holidays.find((holiday) => isEqual(holiday, new Date(date)))
}
