import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useTranslate } from 'hooks/useTranslate'
import { routes } from 'utils/constants'

export const Logout = () => {
  const { logout } = useAuth0()
  const { language } = useTranslate()

  useEffect(() => {
    logout({ returnTo: `${window.location.origin}${routes.LOGIN}?lng=${language}` })
  }, [language, logout])

  return null
}
