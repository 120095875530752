import { IdentificationProvider } from 'pages/InvestorIdentification/InvestorIdentificationProvider'
import { IdentCaseTransactionType, IdentCaseType } from 'types/graphql-global-types'

export const mapIdentproviderToCaseType = (provider?: IdentificationProvider) => {
  switch (provider) {
    case IdentificationProvider.ID_NOW:
    case IdentificationProvider.POST_IDENT:
      return IdentCaseType.AML
    case IdentificationProvider.ID_NOW_AUTO:
      return IdentCaseType.LINUS
    case IdentificationProvider.ID_NOW_QES:
    case IdentificationProvider.POST_IDENT_QES:
      return IdentCaseType.QES_AML
  }
}

export const mapIdentproviderToTransactionType = (provider?: IdentificationProvider) => {
  switch (provider) {
    case IdentificationProvider.ID_NOW:
      return IdentCaseTransactionType.IDNOW_VIDEO
    case IdentificationProvider.POST_IDENT:
      return IdentCaseTransactionType.LIONWARE_POSTIDENT
    case IdentificationProvider.ID_NOW_AUTO:
      return IdentCaseTransactionType.IDNOW_AUTO
    case IdentificationProvider.ID_NOW_QES:
      return IdentCaseTransactionType.IDNOW_QES
    case IdentificationProvider.POST_IDENT_QES:
      return IdentCaseTransactionType.LIONWARE_POSTIDENT_QES
    default:
      return IdentCaseTransactionType.MANUAL
  }
}
