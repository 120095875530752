import Cookie from 'js-cookie'

declare global {
  interface Window {
    initUtm: any
    getTrackedEvents: any
    dataLayer: {
      push: (event: any) => void
    }
  }
}

export const init = () => {
  window.initUtm()
}

export const getFirstClick = () => {
  try {
    const trackedEvents = window.getTrackedEvents()
    return trackedEvents.length > 0 ? trackedEvents[0] : null
  } catch (e) {
    return null
  }
}

export const getLastClick = () => {
  try {
    const trackedEvents = window.getTrackedEvents()
    return trackedEvents.length > 0 ? trackedEvents[trackedEvents.length - 1] : null
  } catch (e) {
    return null
  }
}

export const getSessionCount = () => {
  try {
    const trackedEvents = window.getTrackedEvents()
    return trackedEvents.length
  } catch (e) {
    return null
  }
}

export const pushEvent = (event: string) => window.dataLayer?.push({ event })

export const getMetaData = (isNewRegistration = true) => {
  if (isNewRegistration) {
    return {
      utmFirstClick: JSON.stringify(getFirstClick()) || null,
      utmLastClick: JSON.stringify(getLastClick()) || null,
      sessionCount: getSessionCount() ?? null,
      ga: Cookie.get('_ga')?.toString() || null,
      gid: Cookie.get('_gid')?.toString() || null,
    }
  } else {
    return {
      utmFirstClick: getFirstClick(),
      utmLastClick: getLastClick(),
      sessionCount: getSessionCount(),
      ga: Cookie.get('_ga'),
      gid: Cookie.get('_gid'),
    }
  }
}
