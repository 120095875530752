import { MarketplaceUser } from 'contexts/UserContext/UserContext'
import {
  MarketplaceUserInvitationStatus,
  MarketplaceUserRegistrationStatus,
  MarketplaceUserSource,
  MarketplaceUserFallbackRegistrationStatus,
} from 'types/graphql-global-types'

import { routes } from './constants'

const isAuth0OrLegacy = (user: MarketplaceUser) =>
  [MarketplaceUserSource.AUTH0_REGISTRATION_SCREEN, MarketplaceUserSource.LEGACY_PLATFORM].includes(
    user?.source!
  )

const isLegacy = (user: MarketplaceUser) =>
  isAuth0OrLegacy(user) &&
  user?.fallbackRegistrationStatus === MarketplaceUserFallbackRegistrationStatus.EMAIL_VERIFIED

const isInvitation = (user: MarketplaceUser) =>
  user?.source === MarketplaceUserSource.INVITATION &&
  [
    MarketplaceUserInvitationStatus.PASSWORD_SET_AND_EMAIL_VERIFIED,
    MarketplaceUserInvitationStatus.INVITED_VIA_SALESFORCE,
  ].includes(user.invitationStatus!)

const isOverview = (user: MarketplaceUser) =>
  [user?.invitationStatus, user?.fallbackRegistrationStatus].includes(
    MarketplaceUserInvitationStatus.CONTACT_DETAILS_WITH_TERMS_AND_NDA_SUBMITTED
  )

type RedirectRulesType = {
  path: string
  conditions: (
    user: MarketplaceUser,
    config: {
      pathname: string
      isClassification: boolean
      isSecretUser: boolean
    }
  ) => boolean
}

export const RedirectRules: RedirectRulesType[] = [
  {
    path: routes.OVERVIEW,
    conditions: (user, { pathname, isClassification }) =>
      (user?.registrationStatus === MarketplaceUserRegistrationStatus.EMAIL_VERIFIED &&
        !isClassification &&
        pathname === routes.REGISTER) ||
      (isOverview(user) && pathname === routes.CONTACT_DETAILS),
  },
  {
    path: routes.REGISTER,
    conditions: (user) =>
      user?.source === MarketplaceUserSource.REGISTRATION &&
      [
        MarketplaceUserRegistrationStatus.LOGIN_CREDENTIALS_PROVIDED,
        MarketplaceUserRegistrationStatus.ACCOUNT_DETAILS_SUBMITTED,
        MarketplaceUserRegistrationStatus.CALENDLY_SCHEDULED,
      ].includes(user.registrationStatus!),
  },
  {
    path: routes.REGISTER_VERIFICATION,
    conditions: (user) =>
      (user?.source === MarketplaceUserSource.REGISTRATION &&
        user?.registrationStatus === MarketplaceUserRegistrationStatus.VERIFICATION_EMAIL_REQUESTED) ||
      (isAuth0OrLegacy(user) && user?.fallbackRegistrationStatus === null),
  },
  {
    path: routes.CONTACT_DETAILS,
    conditions: (user) => isInvitation(user) || isLegacy(user),
  },
  {
    path: routes.CLASSIFICATION,
    conditions: (user, { isClassification, pathname }) =>
      isClassification && ![routes.IMPRINT, routes.CONTACT, routes.ACCOUNT].includes(pathname),
  },
  {
    path: routes.LOGOUT,
    conditions: (user, { pathname, isSecretUser }) => isSecretUser && !pathname.includes(routes.CHECKOUT),
  },
]
