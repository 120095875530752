import { useCallback, useMemo } from 'react'

import { useCalendly, theme } from '@linus-capital/linus-ui'
import { useUser } from 'contexts/UserContext'
import { environment, webAppLocales, CALENDLY_ONBOARDING_LINK } from 'utils/constants'
import { parseJSONString } from 'utils/stringUtils'
import * as uuid from 'uuid'

import { usePlatform } from './usePlatform'
import { useTranslate } from './useTranslate'

const appEnv = process.env.REACT_APP_ENVIRONMENT

const calendlyColor = theme.colors.success.substring(1)

type PublicCalendlyUrlProps = { payload?: string }
type PrivateCalendlyUrlProps = { calendlyUrlPath: string; source: string }
type CalendlyUrlProps = { payload?: string; source?: string }

type CalendlyInputType = {
  utmCampaign: string
}

type LocalUser = {
  firstName: string
  lastName: string
  email: string
  phone: string
}

export const UTM_SOURCE_TYPES = {
  MARKETPLACE_REGISTRATION_ONBOARDING_CALL: 'marketplace-registration-onboarding-call',
  PLATFORM_PROJECT_OVERVIEW: 'platform-project-overview',
}

export const getUtmContent = (userId: string) => {
  const content = {
    marketplaceUserId: userId,
    targetDeploymentIdentifier: `marketplace-monolith-${appEnv}`,
  }
  return JSON.stringify(content)
}

/**
 1. If the marketplaceUser is NOT available set utm_medium=repurposed-for-web-app and set utm_source={appEnv} 
    ---> getPublicCalendlyUrl
 2. If marketplaceUser is available do NOT set utm_medium=repurposed-for-web-app, do set utmContent=getUtmContent(userId), and utm_source is:
    a) marketplace-registration-onboarding-call
    b) platform-project-overview (onboarding call from /overview)
    c) <pagePath> for 15min call
    ---> getPrivateCalendlyUrl
 3. utmCampaign is currently only set on landing pages, and thus only present in public urls.
**/

export const useBuildCalendlyUrl = (localUser?: LocalUser) => {
  const { language } = useTranslate()
  const { isUK } = usePlatform()
  const { user } = useUser()
  const calendly = useCalendly()

  /** for public routes and landing pages **/
  const getPublicCalendlyUrl = useCallback(
    (props?: PublicCalendlyUrlProps) => {
      const utmMedium = '&utm_medium=repurposed-for-web-app'
      const utmContent = `&utm_content=${uuid.v4()}`
      const utmSource = `&utm_source=${appEnv}`
      const campaign = props?.payload ? parseJSONString<CalendlyInputType>(props?.payload)?.utmCampaign : ''
      const utmCampaign = campaign ? `&utm_campaign=${campaign}` : ''
      let calendar = ''

      if (appEnv === environment.PRODUCTION) {
        calendar = isUK
          ? 'linus-uk/15-min-meeting'
          : `linus-finance/15min-${webAppLocales[language as keyof typeof webAppLocales]}`
      } else {
        calendar = 'linus-test/15min'
      }

      return `https://calendly.com/${calendar}?primary_color=${calendlyColor}&hide_gdpr_banner=1${utmMedium}${utmSource}${utmCampaign}${utmContent}`
    },
    [isUK, language]
  )

  /** for private routes (marketplaceUser is available) **/
  const getPrivateCalendlyUrl = useCallback(
    ({ calendlyUrlPath, source }: PrivateCalendlyUrlProps) => {
      let calendar = ''
      const utmSource = `&utm_source=${source}`
      const utmContent = `&utm_content=${getUtmContent(user?.id)}`

      if (calendlyUrlPath && appEnv === environment.PRODUCTION) {
        calendar = calendlyUrlPath
          .replace(/{locale}/, webAppLocales[language as keyof typeof webAppLocales])
          .replace('https://calendly.com/', '')
      } else {
        calendar = 'linus-test/15min'
      }

      return `https://calendly.com/${calendar}?primary_color=${calendlyColor}&hide_gdpr_banner=1${utmSource}${utmContent}`
    },
    [language, user?.id]
  )

  const makeAppointment = useCallback(
    ({ payload, source }: CalendlyUrlProps) => {
      const calendlyUrlPath =
        source === UTM_SOURCE_TYPES.PLATFORM_PROJECT_OVERVIEW
          ? CALENDLY_ONBOARDING_LINK
          : user?.accountManager?.calendlyLinkTemplate
      const url = calendlyUrlPath
        ? getPrivateCalendlyUrl({ calendlyUrlPath, source: source ?? '' })
        : getPublicCalendlyUrl({ payload })

      calendly?.initPopupWidget({
        url,
        prefill: {
          name: `${user?.firstName} ${user?.lastName}`,
          email: user?.email,
        },
      })
    },
    [
      user?.accountManager?.calendlyLinkTemplate,
      user?.firstName,
      user?.lastName,
      user?.email,
      calendly,
      getPrivateCalendlyUrl,
      getPublicCalendlyUrl,
    ]
  )

  const inlineCalendly = useMemo(() => {
    const utm = {
      utmSource: UTM_SOURCE_TYPES.MARKETPLACE_REGISTRATION_ONBOARDING_CALL,
      utmContent: getUtmContent(user?.id),
    }

    const link = CALENDLY_ONBOARDING_LINK.replace(
      /{locale}/,
      webAppLocales[language as keyof typeof webAppLocales]
    )
    const url = `${link}?primary_color=${calendlyColor}&hide_gdpr_banner=1&hide_event_type_details=1`

    return {
      url,
      prefill: {
        name: `${localUser?.firstName || user?.firstName} ${localUser?.lastName || user?.lastName}`,
        email: `${localUser?.email || user?.email}`,
        customAnswers: {
          a1: `${localUser?.phone ?? user?.phone}`,
        },
      },
      utm,
    }
  }, [
    language,
    localUser?.email,
    localUser?.firstName,
    localUser?.lastName,
    localUser?.phone,
    user?.email,
    user?.firstName,
    user?.id,
    user?.lastName,
    user?.phone,
  ])

  return { makeAppointment, inlineCalendly, getPublicCalendlyUrl }
}
