import React, { PropsWithChildren } from 'react'

import { useRedirect } from 'hooks/useRedirect'
import { Navigate, useLocation } from 'react-router-dom'

export const RedirectHandler = ({ children }: PropsWithChildren<{}>) => {
  const { pathname, search } = useLocation()
  const redirect = useRedirect()

  const from = `${pathname}${search}`
  const to = `${redirect}${search}`

  if (redirect && from !== to) {
    return <Navigate to={`${redirect}${search}`} replace />
  }

  return <>{children}</>
}
