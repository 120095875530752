import { useTranslation } from 'react-i18next'
import { CurrencyIsoCode, SalesforceLanguage } from 'types/graphql-global-types'
import { currencyLocaleMap, salesforceLanguage as languageConstants, webAppLocales } from 'utils/constants'

type DateProps = (value: Date, options?: Intl.DateTimeFormatOptions) => string

type UseTranslateProps<T> = {
  translate: (key: string, options?: any) => T
  language: string
  salesforceLanguage: () => string
  changeLanguage: (lng: string) => void
  formatNumber: (val: number, minimumFractionDigits: number) => string
  formatCurrency: (
    localValue: number,
    currency?: CurrencyIsoCode | null,
    minimumFractionDigits?: number | null
  ) => string
  formatDate: DateProps
  formatDateTime: DateProps
}

export const useTranslate = <T extends string | object = string>(
  namespace?: string[]
): UseTranslateProps<T> => {
  const { t, i18n } = useTranslation(namespace)

  const currentLanguage = languageConstants[i18n.language as keyof typeof languageConstants]
    ? i18n.language
    : webAppLocales.en

  return {
    translate: (key, options?) => t(key, '', options),
    language: currentLanguage,
    salesforceLanguage: () =>
      SalesforceLanguage[
        (languageConstants[
          i18n.language as keyof typeof languageConstants
        ]?.toUpperCase() as keyof typeof SalesforceLanguage) ?? SalesforceLanguage.EN
      ],
    changeLanguage: async (lng) => {
      await i18n.changeLanguage(lng)
    },
    formatNumber: (val: number, minimumFractionDigits: number) =>
      t('intlNumber', {
        val,
        lng: currentLanguage,
        minimumFractionDigits,
      }),
    formatCurrency: (
      localValue: number,
      currency?: CurrencyIsoCode | null,
      minimumFractionDigits?: number | null
    ) =>
      t('intlCurrencyWithUniqueFormatOptions', {
        localValue,
        minimumFractionDigits: minimumFractionDigits || 0,
        maximumFractionDigits: 2,
        formatParams: {
          localValue: {
            ...(currency ? currencyLocaleMap[currency] : currencyLocaleMap.EUR),
            locale: currentLanguage,
          },
        },
      }),
    formatDate: (val: Date, options?: Intl.DateTimeFormatOptions) => t('intlDateTime', { val, ...options }),
    formatDateTime: (val: Date, options?: Intl.DateTimeFormatOptions) =>
      t('intlDateTime', {
        val,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        ...options,
      }),
  }
}
