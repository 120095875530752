import { useMemo } from 'react'

import { getPlatform, isProduction, Platform } from 'utils/constants'

export const usePlatform = () => {
  // This will allow dev to change platform locally on the fly
  const devPlatform = new URLSearchParams(window.location.search).get('platform')

  const platform = useMemo(
    () => (!isProduction && devPlatform ? devPlatform.toLowerCase() : getPlatform()),
    [devPlatform]
  ) as Platform

  return { platform, isUK: platform === Platform.UK, isEU: platform === Platform.EU }
}
