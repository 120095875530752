import React from 'react'

import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Loading } from 'components/Loading'
import { useApolloProvider } from 'contexts/ApolloProvider/ApolloProvider'
import { usePlatform } from 'hooks/usePlatform'
import { useTranslate } from 'hooks/useTranslate'
import { useLocation } from 'react-router-dom'
import { routes } from 'utils/constants'
import { getRedirectUrl } from 'utils/pathUtils'

export const PrivateRoute = ({ component }: React.PropsWithChildren<any>) => {
  const { language } = useTranslate()
  const { platform } = usePlatform()
  const location = useLocation()
  const { allowTokenRefetch } = useApolloProvider()

  if (
    // @ts-ignore
    [routes.REGISTER_VERIFICATION, routes.REGISTER].includes(location.state?.from.pathname) &&
    allowTokenRefetch
  ) {
    return component
  } else {
    const Component = withAuthenticationRequired(component, {
      onRedirecting: () => <Loading />,
      returnTo: location.pathname,
      loginOptions: {
        redirect_uri: getRedirectUrl(language),
        fragment: encodeURIComponent(JSON.stringify({ platform, isRelaunchUser: true })),
      },
    })

    return <Component />
  }
}
