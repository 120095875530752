import { useCallback } from 'react'

import { useToast } from '@linus-capital/linus-ui'
import { useUser } from 'contexts/UserContext'
import { MarketplaceUser } from 'contexts/UserContext/UserContext'
import { useBuildCalendlyUrl } from 'hooks/useBuildCalendlyUrl'
import { useTranslate } from 'hooks/useTranslate'
import { useNavigate } from 'react-router-dom'
import { logError } from 'services/sentry'

export type Action =
  | { action: 'follow-link'; payload: string }
  | { action: '15min-call'; payload: string }
  | { action: 'link'; payload: string }
  | { action: 'show-locked-notification'; payload: unknown }
  | { action: 'download-document'; payload: string }
  | { action: 'get-user'; payload: (user: MarketplaceUser | null | undefined) => void }

export const useActionExecutor = () => {
  const { addToast } = useToast()
  const { makeAppointment } = useBuildCalendlyUrl()
  const { translate } = useTranslate()
  const navigate = useNavigate()
  const { user } = useUser()

  const actionExecute = useCallback<(action: Action) => void>(
    (builderEvent) => {
      switch (builderEvent.action) {
        case '15min-call':
          makeAppointment({ payload: builderEvent.payload })
          break
        case 'show-locked-notification':
          addToast({
            message: translate('projectOverview.onboarding.lockedNotification'),
            position: 'bottom-right',
            status: 'info',
          })
          break
        case 'download-document':
          window.open(`/download/${builderEvent.payload}?closeTab=1&type=PROJECT`, '_blank')
          break
        case 'follow-link':
          if (builderEvent.payload.substring(0, 2) === '//') {
            window.open(builderEvent.payload, '_blank')
          } else {
            navigate(builderEvent.payload)
          }
          break
        case 'get-user':
          builderEvent.payload(user)
          break
        case 'link':
          navigate(builderEvent.payload)
          break
        default:
          logError(new Error(`Unknown action ${builderEvent}`))
          break
      }
    },
    [addToast, makeAppointment, navigate, translate, user]
  )

  return { actionExecute }
}
